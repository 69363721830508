body {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 15vh 8vw;
  display: flex;
}

h1 {
  opacity: .8;
  margin: 0 0 0 -2px;
  padding: 0;
  font-size: 3.8em;
  font-weight: 700;
  line-height: 1.2;
}

::selection {
  color: #000;
  background: #33ffbd;
}

.hero-image {
  max-width: calc(min(80vw, 1200px));
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 700px) and (width <= 900px) {
  body {
    padding: 200px 10px;
    font-size: 12px;
  }

  h1 {
    font-size: 5.4em;
    line-height: 1;
  }
}

@media (width <= 700px) {
  body {
    padding: 140px 10px;
    font-size: 12px;
  }

  h1 {
    font-size: 5.4em;
    line-height: 1;
  }
}
/*# sourceMappingURL=marketing.e4493880.css.map */
