body {
  padding: 15vh 8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

h1 {
  font-size: 3.8em;
  font-weight: 700;
  opacity: 0.8;
  margin: 0;
  padding: 0;
  line-height: 1.2;
  margin-left: -2px;
}

::selection {
  color: black;
  background: #33ffbd;
}

.hero-image {
  max-width: calc(min(80vw, 1200px));
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 700px) and (max-width: 900px) {
  body {
    padding: 200px 10px;
    font-size: 12px;
  }
  h1 {
    font-size: 5.4em;
    line-height: 1;
  }
}

@media (max-width: 700px) {
  body {
    padding: 140px 10px;
    font-size: 12px;
  }
  h1 {
    font-size: 5.4em;
    line-height: 1;
  }
}
